@use 'assets/styles/utils/helper' as h;

.passwordErrorContainer {
  display: flex;
  width: 100%;
  max-width: 300px;
  margin: h.rem(5px 0);
  font-size: h.rem(12px);
}

.passwordErrorContainerWrap {
  display: flex;
  flex-direction: column;
}

.criteria {
  font-size: h.rem(12px);
  margin: h.rem(5px) 0 0;
}
