@use 'assets/styles/utils/helper' as h;

.button {
  @include h.responsive-variations(margin-top, (s: h.rem(20px), m: h.rem(30px)));
  padding: 0 h.rem(30px);
  font-size: h.rem(16px);
  line-height: 1.5;
  height: h.rem(46px);
  border: solid var(--primary-color) 1px;
  font-family: var(--secondary-font);
  font-weight: 400;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--transparent);
  color: inherit;
  transition: border-color 0.25s, background-color 0.25s, transform 0.25s;

  .caret {
    display: inline-block;
    transition: color 0.25s;
    color: var(--primary-color);
    margin-left: h.rem(6px);
    font-size: h.rem(14px);
    padding-bottom: 2px;
  }

  &:hover {
    border-color: transparent;
    background: var(--accent-orange);
    transform: rotate(-1deg);

    .buttonCaret {
      color: var(--black);
    }
  }

  &.fullWidth {
    width: 100%;

    &:hover {
      transform: none;
    }
  }

  &.expandDown {
    .caret {
      padding-bottom: 5px;
    }
  }

  &.inlineFormButton {
    padding: 0;
    color: var(--grey-100);
    padding-top: h.rem(3px);
    border-color: var(--orange-30);
    width: h.rem(135px);
    height: 44px;
    margin: 0.5rem 0 0 h.rem(10px);

    &:hover {
      border-color: var(--accent-orange);
    }

    @include h.breakpoint-m {
      margin: 0.5rem 0 0 0.625rem;
      font-size: h.rem(20px);
    }

    .caret {
      display: none;
    }
  }

  &.submit {
    margin-top: h.rem(20px);
  }
}

.right {
  text-align: center;

  @include h.breakpoint-m {
    text-align: right;
  }
}

.center {
  text-align: center;
}
