@use 'assets/styles/utils/helper' as h;

.back {
  position: absolute;
  width: h.rem(44px);
  height: h.rem(44px);
  top: 0;
  left: 0;
  z-index: 2;
  border: none;
  background-color: transparent;
}
