@use 'assets/styles/utils/helper' as h;

// TEMP STYLES to be removed after bypass is no longer needed
.temp {
  margin: 0 auto;
  width: 300px;
  text-align: center;

  p {
    margin: 5px 0;
  }

  dialog & {
    position: absolute;
    top: -55px;
    background-color: var(--white);
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
  margin: auto;
  min-height: h.rem(543px);
  max-width: h.rem(500px);
  background-color: var(--accent-orange-light);
  color: var(--grey-70);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--secondary-font);

  input {
    display: block;
    position: relative;
    line-height: h.rem(53px);
    width: h.rem(300px);
    margin: 0;
    border-radius: 0;
    border-width: 1px;
    border-style: solid;
    border-color: var(--grey-40);
    padding: h.rem(0 10px);
    font-size: h.rem(18px);
  }

  .errorInput {
    border-color: #ad0e00;
  }

  .errorMessage {
    font-size: h.rem(12px);
    font-weight: 700;
    color: #ad0e00;
    margin: h.rem(5px) 0 0;
    width: 100%;
    max-width: h.rem(300px);
  }

  input[type='checkbox'] {
    appearance: none;
    display: flex;
    height: h.rem(30px);
    width: h.rem(30px);
    background-color: var(--white);
    border: 1px solid var(--grey-40);
    border-radius: 0;
    justify-content: center;
    align-items: center;
  }

  input[type='checkbox']::before {
    content: '\2713';
    height: 30px;
    width: 30px;
    transform: scale(0);
    color: var(--black);
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkboxError[type='checkbox'] {
    border-color: #ad0e00;
  }

  h2 {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: h.rem(24px);
    text-align: center;
    line-height: 1.5;
    padding: 0;
    margin: 0;
  }

  .formSubmitBtn {
    background: transparent;

    &:disabled {
      background: var(--grey-80);
    }
  }
}

.screen {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: h.rem(30px);
  height: 100%;
}

.wrapper__fluid {
  width: 100%;
}

.wrapper__fixed {
  width: 100%;

  @include h.breakpoint-m {
    width: h.rem(375px);
  }
}

.label {
  display: block;
  text-align: center;
  margin: h.rem(20px 0 7px);
  font-size: h.rem(18px);
  line-height: 1.5;
  font-weight: 400;
}

.description {
  text-align: center;
  font-size: h.rem(18px);
  line-height: 1.5;
  margin: h.rem(20px 0 0);
}

.eye {
  width: h.rem(44px);
  height: h.rem(44px);
  position: absolute;
  right: 0;
  top: 12%;
  border: none;
  background: transparent;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonLink {
  display: inline-block;
  background: transparent;
  border: none;
  font-size: h.rem(16px);
  font-weight: 700;
  line-height: 150%;
  padding-top: h.rem(20px);
  text-decoration: underline;
  text-decoration-color: var(--accent-orange);
  text-decoration-thickness: h.rem(2px);
  text-underline-offset: h.rem(4px);
}

.userEmail {
  font-size: h.rem(18px);
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
}

.termsContainer {
  font-size: h.rem(12px);
  line-height: 1.25;
  max-width: h.rem(300px);
  padding-top: h.rem(20px);

  a {
    text-decoration: underline;
    font-weight: 700;
  }

  .errorMessage {
    margin: 0 0 h.rem(5px);
  }
}

.checkboxContainer {
  display: flex;
  gap: h.rem(11px);

  p {
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.loader {
  position: absolute;
  z-index: 10;
  top: 100px;
  left: calc(50% - 25px);
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid var(--accent-orange);
  border-right-color: transparent;
  animation: l2 1s infinite linear;
}

@keyframes l2 { to { transform: rotate(1turn); } }
