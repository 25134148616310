@use 'assets/styles/utils/helper' as h;

.enterCode {
  margin-bottom: h.rem(5px);
}

.smallTxt {
  font-size: h.rem(12px);
  text-align: center;
  margin: h.rem(5px) 0 0;
}
