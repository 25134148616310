@use 'assets/styles/utils/helper' as h;

.googleLogin {
  background-color: var(--white);
  height: h.rem(44px);
  width: h.rem(280px);
  border-radius: 0;
  font-size: h.rem(16px);
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: h.rem(10px);
  border: 1px solid var(--grey-40);
}
